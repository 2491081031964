import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../component/navbar/Navbar";
import userinfo_api from "../../api/UserInfo";
import invoiceinfo_api from "../../api/InvoiceInfo";
import { Label } from "../../component/ui/label";
import { Input } from "../../component/ui/input";
import { cn } from "../../lib/utils";
import backgroundImage from "../../assetes/img/9D6kyBfmgiv4HD4HjZDrVWpBhI.jpg";
import { Copy } from "iconsax-react";
import cryptoPayment_api from "../../api/cryptoPayment";
import CallBackTelBot_api from "../../api/CallBackTelBot";

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
function InvoicePayment() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const { id } = useParams();
  const [pay_el, setPayEl] = useState(<></>);

  const handleCopy = (text) => {
    toast.success("آدرس کپی شد.");
    navigator.clipboard.writeText(text);
  };
  const handleNumberFormatter = (number, multiply = 1) => {
    return (number * multiply).toLocaleString();
  };
  const cryptoPayment_submit = async () => {
    const hashId = document.getElementById("hash_id").value;
    if (hashId.length > 0) {
      if (hashId.length > 10) {
        const payment_data = await cryptoPayment_api(hashId, id);
        if (payment_data[1] === 200) {
          toast.success(payment_data[0].message);
          const telLinkData = await CallBackTelBot_api();
          if (telLinkData[1] === 200) {
            const telLink = telLinkData[0].data.link;
            setTimeout(() => {
              window.open(telLink, "_blank", "noopener,noreferrer");
            }, 1.5);
            setTimeout(() => {
              window.location.href = "/";
            }, 1.5);
          } else {
          }
        } else if (payment_data[1] === 406) {
          toast.error(payment_data[0].message);
        } else if (payment_data[1] === 401) {
          toast.error("مجدد لاگین کنید");
          setTimeout(() => {
            window.location.href = `/Login?call_back=/invoice/payment/${id}`;
          }, 1.5);
        } else if (payment_data[1] === 403) {
          toast.error("این فاکتور برای شما نیست");
          setTimeout(() => {
            window.location.href = "/";
          }, 1.5);
        } else {
          console.log(payment_data);
          if (payment_data?.[0].errors?.[0]?.msg) {
            toast.error(payment_data[0].errors[0].msg);
          } else {
            toast.error("مشکلی پیش آمده");
            setTimeout(() => {
              window.location.href = "/";
            }, 1.5);
          }
        }
      } else {
        toast.error("مقدار Hash نمیتواند کوتاه باشد");
      }
    } else {
      toast.error("مقدار Hash نمیتواند خالی باشد");
    }
  };
  const invoice_data = async () => {
    const inv_data = await invoiceinfo_api(id);
    if (inv_data[1] === 200) {
      const json_data = inv_data[0].data;
      const json_metadata = inv_data[0].metadata;
      if (json_data.currency === "USDT") {
        setPayEl(
          <div dir="rtl" className="dark flex flex-col items-center">
            <div className="flex flex-col gap-[5px] items-center mt-5">
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">
                  {handleNumberFormatter(json_data.full_value)}
                </span>{" "}
                <span className="text-[white]">
                  {handleNumberFormatter(json_data.currency)}
                </span>{" "}
                <span className="text-[#A9A9A9]">مبلغ کل:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">{json_data.discount}</span>{" "}
                <span className="text-[white]">{json_data.currency}</span>{" "}
                <span className="text-[#A9A9A9]">تخفیف:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">{json_data.tax}</span>
                <span className="text-[white]">{json_data.currency}</span>{" "}
                <span className="text-[#A9A9A9]">مالیات:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-col-reverse items-center my-3 gap-[5px]">
                <span
                  className="flex flex-row-reverse gap-[15px]"
                  style={{
                    direction: "rtl",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "0% 10%",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    textFillColor: "transparent",
                    color: "rgb(255, 255, 255)",
                    fontSize: "3em",
                    fontFamily: "dana",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <span>
                    {json_data.full_value > json_data.discount
                      ? json_data.full_value - json_data.discount
                      : 0}
                  </span>{" "}
                  <span>{json_data.currency}</span>
                </span>
                <span className="text-[#A9A9A9]">مبلغ قابل پرداخت:</span>
              </span>
            </div>
            <div className="flex flex-col items-center gap-[10px]">
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data=${json_metadata.crypto_address}`}
                alt=""
              />
              <span
                onClick={() => handleCopy(json_metadata.crypto_address)}
                className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
              >
                {json_metadata.crypto_address} <Copy />
              </span>
              <div className="flex gap-[10px] text-[#A9A9A9]">
                <span>{json_metadata.crypto_network}</span>
                <span className="font-bold">{json_metadata.crypto_symbol}</span>
              </div>
            </div>
            <div className="flex flex-col my-[50px] gap-[20px] w-[70%] min-w-[300px] max-w-[500px]">
              <LabelInputContainer className="mb-4">
                <Label htmlFor="hash_id">مقدار Hash پرداخت</Label>
                <Input
                  id="hash_id"
                  placeholder="مقدار Hash یا TXID"
                  type="text"
                />
              </LabelInputContainer>{" "}
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                type="submit"
                onClick={() => cryptoPayment_submit()}
              >
                بررسی پرداخت
                <BottomGradient />
              </button>
            </div>
          </div>
        );
      } else if (json_data.currency === "TMN_RECEIPT") {
        setPayEl(
          <div dir="rtl" className="dark flex flex-col items-center">
            <div className="flex flex-col gap-[5px] items-center mt-5">
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">{"تومان"}</span>{" "}
                <span className="text-[white]">
                  {handleNumberFormatter(
                    json_data.full_value,
                    json_metadata.crypto_price
                  )}
                </span>
                <span className="text-[#A9A9A9]">مبلغ کل:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">{"تومان"}</span>{" "}
                <span className="text-[white]">
                  {handleNumberFormatter(
                    json_data.discount,
                    json_metadata.crypto_price
                  )}
                </span>
                <span className="text-[#A9A9A9]">تخفیف:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                <span className="text-[white]">{"تومان"}</span>{" "}
                <span className="text-[white]">
                  {handleNumberFormatter(
                    json_data.full_value > json_data.discount
                      ? json_data.tax
                      : 0,
                    json_metadata.crypto_price
                  )}
                </span>
                <span className="text-[#A9A9A9]">مالیات:</span>
              </span>
              <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
              </div>
              <span className="flex flex-col-reverse items-center my-3 gap-[5px]">
                <span
                  className="flex flex-row-reverse gap-[15px]"
                  style={{
                    direction: "rtl",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "0% 10%",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    textFillColor: "transparent",
                    color: "rgb(255, 255, 255)",
                    fontSize: "3em",
                    fontFamily: "dana",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <span>{"تومان"}</span>
                  <span>
                    {json_data.full_value > json_data.discount
                      ? handleNumberFormatter(
                          json_data.full_value -
                            json_data.discount +
                            json_data.tax,
                          json_metadata.crypto_price
                        )
                      : 0}
                  </span>{" "}
                </span>
                <span className="text-[#A9A9A9]">مبلغ قابل پرداخت:</span>
              </span>
            </div>
            <div className="flex flex-col items-center gap-[10px] min-w-[300px] max-w-[400px] w-[50%] relative">
              <img
                className="w-full h-full object-cover"
                src={json_metadata.tmn_receipt_img}
                alt=""
              />
              <div className="absolute bottom-[15px] left-0 right-0 flex flex-col items-center gap-[5px] w-[100%] z-10 text-[1rem] md:text-xl">
                <span className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer">
                  {json_metadata.tmn_receipt_name}
                </span>
                <span
                  dir="ltr"
                  onClick={() =>
                    handleCopy(json_metadata.tmn_receipt_card_number)
                  }
                  className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
                >
                  <Copy />
                  {json_metadata.tmn_receipt_card_number}
                </span>
                <span
                  onClick={() =>
                    handleCopy(json_metadata.tmn_receipt_card_sheba)
                  }
                  className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
                >
                  {json_metadata.tmn_receipt_card_sheba} <Copy />
                </span>
              </div>
            </div>
            <div className="flex flex-col my-[50px] gap-[20px] w-[70%] min-w-[300px] max-w-[500px]">
              <LabelInputContainer className="mb-4">
                <Label htmlFor="hash_id">مقدار Hash پرداخت</Label>
                <Input
                  id="hash_id"
                  placeholder="مقدار Hash یا TXID"
                  type="text"
                />
              </LabelInputContainer>{" "}
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                type="submit"
                onClick={() => cryptoPayment_submit()}
              >
                ارسال رسید
                <BottomGradient />
              </button>
            </div>
          </div>
        );
      }
    } else if (inv_data[1] === 401) {
      window.location.href = `/Login?call_back=/invoice/payment/${id}`;
    }
  };

  const first_data = async () => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
    }
  };
  useEffect(() => {
    first_data();
    invoice_data();
  }, []);
  return (
    <div>
      <ToastContainer theme="dark" rtl={true} position="bottom-right" />
      <Navbar name={name} phone={phone} />
      {pay_el}
    </div>
  );
}
export default InvoicePayment;
