import Navbar from "../../component/navbar/Navbar";
import style from "./Home.module.css";
import daniHead from "../../assetes/img/Danial.png";
import daniHead2 from "../../assetes/img/dani-back (2).png";
import btcicon from "../../assetes/icons/BTC-ICON.png";
import catdogicon from "../../assetes/icons/CATDOG-ICON.png";
import dogeicon from "../../assetes/icons/DOGE-ICON.png";
import ethicon from "../../assetes/icons/ETH-ICON.png";
import pepeicon from "../../assetes/icons/PEPE-ICON.png";
import pndcicon from "../../assetes/icons/PNDC-ICON.png";
import daniFree from "../../assetes/img/dani-free-b1.png";
import { ArrowLeft2 } from "iconsax-react";
import { Link } from "react-router-dom";
import Particles from "../../component/magicui/particles";
import OrbitingCircles from "../../component/magicui/orbiting-circles";
import AnimatedGridPattern from "../../component/magicui/animated-grid-pattern";
import { cn } from "../../lib/utils";
import TextReveal from "../../component/magicui/text-reveal";
import { Carousel, Card } from "../../component/ui/apple-cards-carousel";
import Telegram from "../../assetes/img/Telegram.png";
import TelegramMob from "../../assetes/img/Telegram-mob.png";
import Mosh from "../../assetes/img/Mosh.png";
import MoshMob from "../../assetes/img/MoshMob.png";
import insta from "../../assetes/img/insta.png";
import instaMob from "../../assetes/img/instaMob.png";
import telSup from "../../assetes/img/telSup.png";
import telSupMob from "../../assetes/img/telSupMob.png";
import c1 from "../../assetes/course/c-1.jpg";
import c2 from "../../assetes/course/c-2.jpg";
import c3 from "../../assetes/course/c-3.jpg";
import c4 from "../../assetes/course/c-4.jpg";
import c5 from "../../assetes/course/c-5.jpg";
import c6 from "../../assetes/course/c-6.jpg";
import c7 from "../../assetes/course/c-7.jpg";
import c8 from "../../assetes/course/c-8.jpg";
import c9 from "../../assetes/course/c-9.jpg";
import Marquee from "../../component/magicui/marquee";
import { VelocityScroll } from "../../component/magicui/scroll-based-velocity";
import { ContainerScroll } from "../../component/ui/container-scroll-animation";
import { Label } from "../../component/ui/label";
import { Input } from "../../component/ui/input";
import unkprof from "../../assetes/icons/unk_prof.jpg";
import { GlareCard } from "../../component/ui/glare-card";
import { WavyBackground } from "../../component/ui/wavy-background";
import ShineBorder from "../../component/magicui/shine-border";
import userinfo_api from "../../api/UserInfo";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import plans_api from "../../api/Plans";
import buyPlans_api from "../../api/buyPlans";

const reviews = [
  {
    name: "Jack",
    username: "@jack",
    body: "I've never seen anything like this before. It's amazing. I love it.",
    img: "https://avatar.vercel.sh/jack",
  },
  {
    name: "Jill",
    username: "@jill",
    body: "I don't know what to say. I'm speechless. This is amazing.",
    img: "https://avatar.vercel.sh/jill",
  },
  {
    name: "John",
    username: "@john",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/john",
  },
  {
    name: "Jane",
    username: "@jane",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/jane",
  },
  {
    name: "Jenny",
    username: "@jenny",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/jenny",
  },
  {
    name: "James",
    username: "@james",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/james",
  },
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({ img, name, username, body }) => {
  return (
    <figure className="bg-black/80 relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4 border-gray-50/[.1] bg-gray-50/[.10] hover:bg-gray-50/[.15]">
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm text-white/80">{body}</blockquote>
    </figure>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

function Home() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [buy_method_el, setBuyMethodEl] = useState(<></>);
  const [plans_el, setPlansEl] = useState(<></>);
  const DummyContent1 = () => {
    return (
      <>
        {[...new Array(3).fill(1)].map((_, index) => {
          return (
            <div
              key={"dummy-content" + index}
              className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
            >
              <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
                <span className="font-bold text-neutral-700 dark:text-neutral-200">
                  The first rule of Apple club is that you boast about Apple
                  club.
                </span>{" "}
                Keep a journal, quickly jot down a grocery list, and take
                amazing class notes. Want to convert those notes to text? No
                problem. Langotiya jeetu ka mara hua yaar is ready to capture
                every thought.
              </p>
              <img
                src="https://assets.aceternity.com/macbook.png"
                alt="Macbook mockup from Aceternity UI"
                height="500"
                width="500"
                className="md:w-1/2 md:h-1/2 h-full w-full mx-auto object-contain"
              />
            </div>
          );
        })}
      </>
    );
  };

  // const handleSubmit = (e) => {
  //   console.log("Form submitted");
  // };

  const handleFreeCourse = () => {
    window.location.href = "/courses/66ddbf56716e60ca7ec1eca2";
  };

  const data = [
    {
      category: "مقدمه",
      title: "قسمت اول",
      src: c1,
      content: <DummyContent1 />,
    },
    {
      category: "انواع و نحوه نصب کیف پول",
      title: "قسمت دوم",
      src: c2,
      content: <DummyContent1 />,
    },
    {
      category: "نحوه شارژ کیف پول",
      title: "قسمت سوم",
      src: c3,
      content: <DummyContent1 />,
    },
    {
      category: "نحوه ادد کردن توکن در کیف پول",
      title: "قسمت چهارم",
      src: c4,
      content: <DummyContent1 />,
    },
    {
      category: "ترید در صرافی Pancakeswap",
      title: "قسمت پنجم",
      src: c5,
      content: <DummyContent1 />,
    },
    {
      category: "ترید در صرافی Uniswap",
      title: "قسمت ششم",
      src: c6,
      content: <DummyContent1 />,
    },
    {
      category: "ترید در صرافی Raydium",
      title: "قسمت هفتم",
      src: c7,
      content: <DummyContent1 />,
    },
    {
      category: "آموزش سایت Dexview",
      title: "قسمت هشتم",
      src: c8,
      content: <DummyContent1 />,
    },
    {
      category: "آموزش سایت Pump.fun",
      title: "قسمت نهم",
      src: c9,
      content: <DummyContent1 />,
    },
  ];

  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  const first_data = async () => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
    }
  };
  const buy_plan = async (plan_id, plan_time, currency) => {
    const buyPlan_data = await buyPlans_api(plan_id, plan_time, currency);
    if (buyPlan_data[1] === 200) {
      window.location.href = `/invoice/payment/${buyPlan_data[0][0]._id}`;
    } else if (buyPlan_data[1] === 401) {
      window.location.href = "/Login";
    }
  };
  const close_payment_method = () => {
    setBuyMethodEl(<></>);
  };
  const Payment_method = (plan_id, plan_time) => {
    buy_plan(plan_id, plan_time, "USDT");
    // setBuyMethodEl(
    //   <div
    //     onClick={() => close_payment_method()}
    //     className="fixed z-[101] bg-[#4343434e] backdrop-blur-md h-[100%] w-[100%] flex items-center justify-center flex-col gap-2"
    //   >
    //     <span className="text-[#fff] font-bold">روش پرداخت را انتخاب کنید</span>
    //     <div className="flex flex-col gap-1 items-center">
    //       <div
    //         className="cursor-pointer flex items-center rounded-md justify-center h-[60px] min-w-[200px] w-[50%] max-w-[500px] text-center bg-[#505050] hover:bg-[#7E42FF] transition duration-500"
    //         onClick={() => buy_plan(plan_id, plan_time, "USDT")}
    //       >
    //         <span className="text-[#fff]">کریپتو</span>
    //       </div>
    //       <div
    //         className="cursor-pointer flex items-center rounded-md justify-center h-[60px] min-w-[200px] w-[50%] max-w-[500px] text-center bg-[#505050] hover:bg-[#7E42FF] transition duration-500"
    //         onClick={() => buy_plan(plan_id, plan_time, "TMN_RECEIPT")}
    //       >
    //         <span className="text-[#fff]">کارت به کارت</span>
    //       </div>
    //     </div>
    //   </div>
    // );
  };
  const plans_data = async () => {
    const plans_req_data = await plans_api();
    if (plans_req_data[1] === 200) {
      const plans_json = plans_req_data[0].data;
      setPlansEl(
        <div className="flex flex-col items-center">
          <span dir="rtl" className="text-white font-bold text-4xl">
            {plans_json.name}
          </span>

          <span dir="rtl" className="text-white mt-6 p-5 text-center">
            برای عضویت در کانال MemeHouse کافیه یکی از اشتراک پایین رو انتخاب
            کنی
          </span>
          <div className="bg-black grid-cols-1 md:grid-cols-3 p-[50px] gap-10 flex justify-center flex-row flex-wrap sm:flex-row items-center">
            {plans_json.plans.map((plan, index) => (
              <div
                className="cursor-pointer"
                onClick={() => Payment_method(plans_json._id, plan.time)}
              >
                <GlareCard
                  bg={plan.color}
                  className="w-['250px'] flex flex-col items-center justify-center"
                >
                  <div
                    dir="rtl"
                    className="w-full h-full flex items-center justify-start flex-col p-[25px] text-xl"
                  >
                    <div className="flex text-white gap-[5px] flex-col items-center">
                      <span className="font-bold">{plan.name}</span>
                      {/* <span>/</span> */}
                      <div>
                        {plan.discount !== 0 ? (
                          <span className={`text-[#fff]`}>
                            {" / "} {plan.price - plan.discount} تتر
                          </span>
                        ) : null}

                        <span
                          className={`text-[#fff] ${
                            plan.discount !== 0
                              ? "opacity-35 line-through"
                              : "opacity-100"
                          }`}
                        >
                          {plan.price} تتر
                        </span>
                      </div>
                    </div>
                  </div>
                </GlareCard>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  const gotoInsta = () => {
    window.location.href = "https://www.instagram.com/daniibtc/";
  };
  useEffect(() => {
    first_data();
    plans_data();
  }, []);

  return (
    <div id="body_id" className={style.body}>
      {buy_method_el}
      <ToastContainer rtl={true} position="bottom-right" />

      <div className={style.header}>
        <div className="min-[1025px]:flex hidden dark blur-sm h-[800px] left-[40%] bottom-[-400px] absolute border-none bg-black  w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background z-1 md:shadow-xl">
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={21}
            radius={window.innerHeight / 5}
          >
            <img style={{ width: 70 }} src={catdogicon} alt="" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={14}
            radius={window.innerHeight / 5}
          >
            <img style={{ width: 70 }} src={pepeicon} alt="" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={7}
            radius={window.innerHeight / 5}
          >
            <img style={{ width: 70 }} src={pndcicon} alt="" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={21}
            radius={window.innerHeight / 2.7}
            reverse
          >
            <img style={{ width: 110 }} src={btcicon} alt="" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={14}
            radius={window.innerHeight / 2.7}
            reverse
          >
            <img style={{ width: 110 }} src={ethicon} alt="" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[10px] border-none bg-transparent"
            duration={20}
            delay={7}
            radius={window.innerHeight / 2.7}
            reverse
          >
            <img style={{ width: 110 }} src={dogeicon} alt="" />
          </OrbitingCircles>
        </div>

        <Navbar name={name} phone={phone} />
        <div className={style.head}>
          <div className={style.headLeft}>
            <img className={style.daniHead} src={daniHead} />
            <img className={style.daniHead2} src={daniHead2} />
          </div>
          <div style={{ marginBottom: "25px" }} className={style.headRight}>
            <h1>
              آشنایی با جادوی
              <br /> معاملات دیجیتال
            </h1>

            <h3>
              از آموزش مبانی این بازار تا ارائه آخرین تحلیل های بازار، ما تمام
              تلاش خود را برای کمک به شما در درک بهتر و کسب
              <span> مهارت های لازم برای موفقیت</span> در معاملات ارز دیجیتال
              انجام میدهیم.
            </h3>
            <Link
              to="/courses/66ddbf56716e60ca7ec1eca2"
              className={style.freeCourseButton}
            >
              مشاهده دوره رایگان
              <ArrowLeft2 className={style.arrow} size="18" color="#ffffff" />
            </Link>
          </div>
          <Particles
            className="min-[1025px]:flex hidden absolute inset-0 h-[88vh] pointer-events-none"
            quantity={90}
            ease={80}
            color="#ffffff"
            refresh
          />
        </div>
      </div>

      {/* <div
        dir="rtl"
        className="min-h-[16rem] z-10 dark relative bg-black flex w-full items-center justify-center  bg-background p-20 md:shadow-xl"
      >
        <TextReveal text="این یک جمله تست است که استفاده کردم" />
        <AnimatedGridPattern
          numSquares={30}
          maxOpacity={0.1}
          duration={3}
          repeatDelay={1}
          className={cn(
            "[mask-image:radial-gradient(500px_circle_at_center,white,transparent)]",
            "inset-x-0 inset-y-[-30%] h-[200%] skew-y-12"
          )}
        />
      </div> */}

      <div className={style.period}>
        <img
          onClick={handleFreeCourse}
          className={style.periodimg}
          src={daniFree}
        />

        {/* <div className={style.periodText}>
          <div className={style.periodTextL}>
            <a href="#">
              <VideoVertical
                className={style.VideoVertical}
                size="27"
                color="#ffffff"
              />
              لیست دوره
            </a>
          </div>
          <div className={style.periodTextR}>
            <h1>دوره رایگان شروع ترید</h1>
          </div>
        </div> */}

        {/* <div className="dark w-[90%] h-full m-auto">
          <Carousel items={cards} />
        </div> */}
      </div>
      {/* 
      <div className={style.comments}>
        <h1>نظرات کاربران</h1>

        <div className="bg-black relative flex h-[400px] w-full flex-col items-center justify-center overflow-hidden bg-background md:shadow-xl">
          <Marquee
            vertical={true}
            reverse={false}
            pauseOnHover={true}
            className="[--durationn:40s]"
          >
            {firstRow.map((review) => (
              <ReviewCard key={review.username} {...review} />
            ))}
          </Marquee>
          <Marquee
            vertical={true}
            reverse={true}
            pauseOnHover={true}
            className="[--durationn:40s]"
          >
            {secondRow.map((review) => (
              <ReviewCard key={review.username} {...review} />
            ))}
          </Marquee>
          <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-black"></div>
          <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-black"></div>
        </div>
      </div> */}

      {/* <div className={style.container}>
        <div className={style.parent}>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
        </div>
        <div className={style.parent}>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
          <h1>متن تست</h1>
        </div>
      </div> */}

      {/* <div className="bg-black">
        <div
          style={{ fontFamily: "dana", gap: 15, maxWidth: 500 }}
          className="dark max-w-md w-full mx-auto p-4 md:p-8 shadow-input bg-black flex flex-row items-center"
        >
          <LabelInputContainer className="mb-4">
            <Label htmlFor="tel_id">نام کاربری تلگرام</Label>
            <Input id="tel_id" placeholder="@" type="tel_id" />
          </LabelInputContainer>
          <img style={{ borderRadius: 300, height: 68 }} src={unkprof} alt="" />
        </div>
      </div> */}

      {/* {plans_el} */}
      {/* <div className="bg-black pt-[200px] overflow-hidden">
        <WavyBackground className="max-w-4xl mx-auto pb-40">
          <p
            style={{ fontFamily: "dana" }}
            className="text-2xl md:text-4xl lg:text-7xl text-white font-bold inter-var text-center"
          >
            اینم یه متن تست دیگه
          </p>
          <p
            style={{ fontFamily: "dana" }}
            className="text-base md:text-lg mt-4 text-white font-normal inter-var text-center"
          >
            بهتره اینجا توضیحات بخش مشاوره قرار بگیره که پایین فرم رو بزاریم
          </p>
        </WavyBackground>
      </div> */}

      {/* <div
        style={{ fontFamily: "DanaFaNum" }}
        className="my-10 dark flex justify-center max-w-md w-full mx-auto rounded-none md:rounded-2xl bg-black"
      >
        {" "}
        <ShineBorder
          className="dark max-w-md w-full mx-auto rounded-none md:rounded-2xl p-[18px] md:p-[18px] bg-black"
          color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
        >
          <div className="relative z-10">
            <h2 dir="rtl" className="font-bold text-xl text-neutral-200">
              ثبت تایم مشاوره
            </h2>
            <p dir="rtl" className="text-sm max-w-sm mt-2 text-neutral-300">
              با استفاده از فرم زیر میتوانید تایم مشاوره خود را ثبت کنید
            </p>
            <h1
              style={{
                direction: "rtl",
                backgroundImage:
                  "url(https://framerusercontent.com/images/9D6kyBfmgiv4HD4HjZDrVWpBhI.jpg)",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "0% 10%",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textFillColor: "transparent",
                color: "rgb(255, 255, 255)",
                fontSize: "3em",
                fontFamily: "dana",
                fontStyle: "normal",
                fontWeight: "bold",
                textAlign: "center",
                margin: 0,
                padding: 0,
              }}
            >
              1,650,000 تومان
            </h1>

            <form className="my-8" onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
                <LabelInputContainer>
                  <Label htmlFor="lastname">نام خانوادگی</Label>
                  <Input
                    dir="rtl"
                    id="lastname"
                    placeholder="سید مختوم"
                    type="text"
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <Label htmlFor="firstname">نام</Label>
                  <Input
                    dir="rtl"
                    id="firstname"
                    placeholder="دانیال"
                    type="text"
                  />
                </LabelInputContainer>
              </div>
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                type="submit"
              >
                ثبت مشاوره
                <BottomGradient />
              </button>
            </form>
          </div>
        </ShineBorder>
      </div> */}
      {/* <div className={style.telegram}>
        <Link
          className="hidden md:block"
          target="_blank"
          to={"https://t.me/GreenTradeAca"}
        >
          <img src={Mosh} />
        </Link>
        <Link
          className="block md:hidden"
          target="_blank"
          to={"https://t.me/GreenTradeAca"}
        >
          <img src={MoshMob} />
        </Link>
      </div> */}
      <div className={style.telegram}>
        <Link
          className="hidden md:block"
          target="_blank"
          to={"https://t.me/GreenTradeAca"}
        >
          <img src={Telegram} />
        </Link>
        <Link
          className="block md:hidden"
          target="_blank"
          to={"https://t.me/GreenTradeAca"}
        >
          <img src={TelegramMob} />
        </Link>
      </div>

      <div className={style.telegram}>
        <Link
          className="hidden md:block"
          target="_blank"
          to={"https://t.me/daniibtc_poshtiban"}
        >
          <img src={telSup} />
        </Link>
        <Link
          className="block md:hidden"
          target="_blank"
          to={"https://t.me/daniibtc_poshtiban"}
        >
          <img src={telSupMob} />
        </Link>
      </div>

      <div className={style.telegram}>
        <Link
          className="hidden md:block"
          target="_blank"
          to={"https://www.instagram.com/daniibtc/"}
        >
          <img src={insta} />
        </Link>
        <Link
          className="block md:hidden"
          target="_blank"
          to={"https://www.instagram.com/daniibtc/"}
        >
          <img src={instaMob} />
        </Link>
      </div>

      <div className={style.footer}>
        <div className={style.footerTap}>
          {/* <div className={style.footerTapL}>
            <div className={style.footerTapLBOX}>
              مشاوره
              <span>جدید</span>
            </div>
            <div className={style.footerTapLBOX}>
              دوره رایگان ترید
              <span>جدید</span>
            </div>
            <div className={style.footerTapLBOX}>نظر دانشجویان</div>
          </div> */}

          <div className={style.footerTapR}>
            {/* <div className={style.footerpad}>
              <Call className={style.icon} size="20" color="#ffffff" />
            </div>

            <div className={style.footerpad}>
              <h2 dir="rtl">پشتیبانی : 150-150-021</h2>
            </div> */}

            {/* <div className={style.footerpad}>
              <Youtube className={style.icon} size="20" color="#ffffff" />
            </div>

            <div className={style.footerpad}>
              <Whatsapp className={style.icon} size="20" color="#ffffff" />
            </div>

            <div className={style.footerpad}>
              <Send2 className={style.icon} size="20" color="#ffffff" />
            </div> */}

            {/* <div onClick={gotoInsta} className={style.footerpad}>
              <Instagram className={style.icon} size="20" color="#ffffff" />
            </div> */}
          </div>
        </div>
        <div className={style.footerBt}>
          <div className={style.footefull}>
            <h2 dir="rtl">
              تمامی حقوق مادی و معنوی این سایت متعلق به daniibtc است
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
